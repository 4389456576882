<template>
    <b-row>
        <b-col sm="12">
            <b-card header-tag="header" footer-tag="footer">
                <b-row>
                    <b-col>
                        <h4 id="traffic" class="card-title mb-0">Finanzas - Configuración</h4>
                        <div class="small text-muted">Configuración de cajas</div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card header-tag="header" footer-tag="footer" no-body>
                <b-row>
                    <b-col>      
                        <b-tabs v-model="tabIndex" card>
                            <b-tab title="Cajas" :title-link-class="linkClass(0)">
                                <tab-cash></tab-cash>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import serviceAPI from './services'
    import Error from '@/handler/error'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper' 
    import TabCash from './settings/crudCash'

    export default {
        components: {
          TabCash,
        },
        data: () => {
            return {      
                access: {
                    module_id: Modules.FINANZAS,
                    profile_id: Profiles.PERSONAL,
                    view_reference: 'settings',
                    elements: {}
                },
                tabIndex: 0
            }
        },
        created () {     
            /* Configurar permisos de vistas y elementos */
            Helper.hasAccessView(this.access)
            /* Fin configuracion */
        }, 
        mounted () {
            
        },
        methods: {
            linkClass(idx) {
                if (this.tabIndex === idx) {
                    return ['bg-primary', 'text-light']
                } else {
                    return ['bg-light', 'text-info']
                }
            },
        } 
    }
</script>